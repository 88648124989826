import BookImage from '@components/BookImage'
import { SuggestionButtonSearch } from '@components/Result'
import { BookCardContainer } from '@components/Result.styles'
import { BookCard, BookIcon, Box, Button, Chevron, Typography, useMediaQuery, useTheme } from '@hermes/web-components'
import { usePathname, useRouter } from 'next/navigation'
import { useState } from 'react'

import { MeiliConsolidatedBookDto } from '@hermes/domains'
import { Book } from '@hermes/types'
import { useLocale } from '@hooks/useLocale'
import { useNavigation } from '@hooks/useNavigation'
import { useStage } from '@hooks/useStage'
import useTranslateMessage from '@hooks/useTranslateMessage'
import { BrowseCategoriesButton, RecentSearchContainer, RecentTitle } from './Recent.styles'

interface RecentSearchProps {
  hideSearch: () => void
  updateTextField: (query: string) => void
}

type MeiliBookVariant = MeiliConsolidatedBookDto['books'][number]

const RecentSearch = ({ hideSearch, updateTextField }: RecentSearchProps) => {
  const router = useRouter()
  const theme = useTheme()
  const locale = useLocale()
  const navigation = useNavigation()
  const stage = useStage()
  const { t } = useTranslateMessage()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const pathname = usePathname()
  const onSearchPage = pathname?.includes('/search')

  const [recentSearch, setRecentSearch] = useState(
    JSON.parse(localStorage.getItem(`recentSearch-${stage}${locale}`) || '[]') || []
  )
  const [recentViewed, setRecentViewed] = useState(
    JSON.parse(localStorage.getItem(`recentViewed-${stage}${locale}`) || '[]') || []
  )
  const clearRecent = (key: 'recentSearch' | 'recentViewed') => {
    localStorage.removeItem(`${key}-${stage}${locale}`)
    if (key === 'recentSearch') setRecentSearch(undefined)
    if (key === 'recentViewed') setRecentViewed(undefined)
  }
  const pushToBookDetailsPage = (book: MeiliBookVariant | Book) => {
    if (window.location.pathname !== `/books/${book.slug}`) hideSearch()
    navigation.navigate.productPage(book.slug)
  }
  const triggerSearch = (value: string) => {
    hideSearch()
    updateTextField(value)
    !onSearchPage && navigation.navigate.searchPage()
  }

  return (
    <>
      {((recentSearch && Boolean(recentSearch.length)) || (recentViewed && Boolean(recentViewed.length))) && (
        <RecentSearchContainer>
          {!isDesktop && (
            <BrowseCategoriesButton onClick={() => router.push(`/${locale}/categories`)}>
              <Box>
                <BookIcon width="20px" />
                {t({ id: 'header.search.recent.browseCategories' })}
              </Box>
              <Chevron width="20px" style={{ rotate: '-90deg' }} />
            </BrowseCategoriesButton>
          )}
          {recentSearch && Boolean(recentSearch.length) && (
            <>
              <RecentTitle>
                <Typography variant="body2">{t({ id: 'header.search.recentSearch' })}</Typography>
                <Button onClick={() => clearRecent('recentSearch')} variant={isDesktop ? 'text' : 'outlined'}>
                  {t({ id: 'clear' })}
                </Button>
              </RecentTitle>
              {recentSearch.map((item: string, index: number) => (
                <Box display="flex" flexDirection="column" key={index}>
                  <SuggestionButtonSearch onClick={() => triggerSearch(item)} label={item} />
                </Box>
              ))}
            </>
          )}
          {recentViewed && Boolean(recentViewed.length) && (
            <>
              <RecentTitle>
                <Typography variant="body2">{t({ id: 'header.search.recentViewed' })}</Typography>
                <Button onClick={() => clearRecent('recentViewed')} variant={isDesktop ? 'text' : 'outlined'}>
                  {t({ id: 'clear' })}
                </Button>
              </RecentTitle>
              {recentViewed.map((item: MeiliBookVariant | Book, index: number) => (
                <BookCardContainer key={index}>
                  <BookCard
                    title={`${item.title}${item.subtitle ? `: ${item.subtitle}` : ''}`}
                    cardAction={() => pushToBookDetailsPage(item)}
                    image={
                      <BookImage
                        src={item.smallImage ?? item.fullImage}
                        width={40}
                        height={56}
                        style={{ objectFit: 'contain' }}
                        alt={item.title}
                      />
                    }
                    cover={t({ id: item.format })}
                    year={String(item.year)}
                    inline
                  />
                </BookCardContainer>
              ))}
            </>
          )}
        </RecentSearchContainer>
      )}
    </>
  )
}

export default RecentSearch
