import { useCallback, useRef } from 'react'

export function useDebounceAction<T>(func: (arg: T) => void, delay: number) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  return useCallback(
    (arg: T) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = setTimeout(() => {
        func(arg)
      }, delay)
    },
    [func, delay]
  )
}
