import React, { SVGProps } from 'react'

const Logo = (props?: SVGProps<SVGSVGElement>) => (
  <svg width="130" height="28" viewBox="0 0 130 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.6793 0.588852H24.4085V10.1842C25.8076 9.11712 27.5358 8.5577 29.3032 8.59984C32.0125 8.59984 34.1558 9.45296 35.7181 11.2008C37.2805 12.9487 38.0962 15.1394 38.0962 17.9009C38.0962 20.9061 37.151 23.302 35.2184 25.0885C33.2858 26.875 30.7782 27.7638 27.5301 27.7638C24.6102 27.7638 21.4855 26.872 19.6733 25.7335L19.6793 0.588852ZM24.4085 14.7322V22.4875C25.4191 23.1128 26.5905 23.4379 27.783 23.4239C30.8655 23.4239 33.045 21.4323 33.045 18.2249C33.045 14.8541 30.989 12.9873 28.1924 12.9873C27.4688 12.9747 26.7518 13.1253 26.0961 13.4277C25.4404 13.7301 24.8632 14.1762 24.4085 14.7322Z"
      fill="black"
    />
    <path
      d="M40.3751 18.1832C40.3751 15.383 41.2782 13.1091 43.1295 11.3226C44.9809 9.53606 47.4463 8.59972 50.4506 8.59972C53.4548 8.59972 55.8691 9.49148 57.7294 11.3226C59.5898 13.1536 60.523 15.383 60.523 18.1832C60.523 20.9833 59.5778 23.2603 57.7294 25.0884C55.8811 26.9165 53.4518 27.7637 50.4506 27.7637C47.4493 27.7637 44.9809 26.8719 43.1295 25.0884C41.2782 23.3048 40.3751 20.9744 40.3751 18.1832ZM50.4385 23.4237C53.3976 23.4237 55.4958 21.3103 55.4958 18.1832C55.4958 15.1393 53.3585 12.9872 50.4385 12.9872C47.5998 12.9872 45.4204 15.0977 45.4204 18.1832C45.4204 21.2687 47.5306 23.4237 50.4385 23.4237Z"
      fill="black"
    />
    <path
      d="M62.7957 18.1832C62.7957 15.383 63.6988 13.1091 65.5501 11.3226C67.4015 9.53606 69.8699 8.59972 72.8712 8.59972C75.8724 8.59972 78.2897 9.49148 80.15 11.3226C82.0104 13.1536 82.9496 15.383 82.9496 18.1832C82.9496 20.9833 82.0014 23.2603 80.153 25.0884C78.3047 26.9165 75.8754 27.7637 72.8742 27.7637C69.8729 27.7637 67.4045 26.8719 65.5531 25.0884C63.7018 23.3048 62.7957 20.9744 62.7957 18.1832ZM72.8712 23.4237C75.8303 23.4237 77.9284 21.3103 77.9284 18.1832C77.9284 15.1393 75.7911 12.9872 72.8712 12.9872C70.0325 12.9872 67.853 15.0977 67.853 18.1832C67.853 21.2687 69.9512 23.4237 72.8712 23.4237Z"
      fill="black"
    />
    <path d="M86.0682 27.3209V0.588852H90.7974V27.3209H86.0682Z" fill="black" />
    <path d="M10.2571 26.1586V0.594742H14.9863V26.1586H10.2571Z" fill="#DB2357" />
    <path d="M106.984 27.3207V9.04852H111.713V27.3207H106.984Z" fill="black" />
    <path
      d="M121.731 8.59972C124.741 8.59972 127.085 9.37158 128.763 10.9153L126.993 14.2446C125.577 13.1057 123.804 12.4888 121.978 12.4997C120.62 12.4997 119.675 13.2309 119.675 14.084C119.675 15.1779 121.237 15.8289 123.17 16.3164C126.006 16.9258 129.708 18.1832 129.666 21.9197C129.666 25.4867 126.746 27.7666 122.183 27.7666C120.551 27.7663 118.938 27.4207 117.454 26.753C115.933 26.0634 114.904 25.3708 114.443 24.6395L116.746 21.5956C117.278 22.204 118.141 22.7321 119.335 23.18C120.34 23.5958 121.413 23.8294 122.502 23.8696C124.558 24.0718 125.997 21.9197 123.902 20.8644C121.31 19.5238 114.937 19.4435 114.937 14.1643C114.946 10.7132 117.782 8.59972 121.731 8.59972Z"
      fill="black"
    />
    <path
      d="M103.38 9.04852L96.4296 17.4132L104.202 27.3207H98.1154L90.7974 17.7789L97.417 9.04852H103.38Z"
      fill="black"
    />
    <path d="M0.166626 25.347L3.29733 7.80905L7.95425 8.62055L4.82354 26.1585L0.166626 25.347Z" fill="#DB2357" />
    <path
      d="M109.44 0.166687C109.8 0.166638 110.156 0.237285 110.487 0.37451C110.819 0.511736 111.12 0.712792 111.372 0.965957C111.624 1.21912 111.823 1.51933 111.956 1.84907C112.09 2.17882 112.155 2.5315 112.149 2.88656C112.149 4.67008 110.876 5.81153 109.44 5.81153C107.754 5.81153 106.478 4.67305 106.478 2.88656C106.49 1.26355 107.806 0.166687 109.44 0.166687Z"
      fill="black"
    />
  </svg>
)

export default Logo
