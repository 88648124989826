import { Locale } from './localeHelpers'

export enum Language {
  SV = 'sv',
  EN = 'en',
  NO = 'no'
}

enum LanguageWithVersion {
  NO_NB = 'nb',
  NO_NN = 'nn',
  SV = Language.SV,
  EN = Language.EN,
  NO = Language.NO
}

export function getLanguageFromLocale(locale: Locale | string): Language {
  switch (locale) {
    case 'se': {
      return Language.SV
    }
    case 'no': {
      return Language.NO
    }
    default: {
      return Language.EN
    }
  }
}

export function getLanguageWithVersionFromLocale(locale: Locale | string): LanguageWithVersion {
  switch (locale) {
    case 'se': {
      return LanguageWithVersion.SV
    }
    case 'no': {
      return LanguageWithVersion.NO_NB
    }
    default: {
      return LanguageWithVersion.EN
    }
  }
}
