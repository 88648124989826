import React, { SVGProps } from 'react'

const LogoMobile = (props?: SVGProps<SVGSVGElement>) => (
  <svg width="32" height="32" viewBox="0 0 44 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M22.7124 2.23291H27.9163V12.7809C29.4516 11.6002 31.3528 10.9792 33.2984 11.0229C36.2833 11.0229 38.6412 11.9616 40.3581 13.8814C42.0749 15.8011 42.9796 18.213 42.9796 21.2649C42.9796 24.5699 41.9396 27.2034 39.8131 29.1688C37.6867 31.1343 34.9262 32.1188 31.3536 32.1188C28.1479 32.1188 24.7035 31.1378 22.7124 29.8861V2.23291ZM27.9163 17.7876V26.3279C29.0263 27.0157 30.3139 27.3722 31.6243 27.3546C35.0188 27.3546 37.416 25.1677 37.416 21.6411C37.416 17.9318 35.1541 15.8784 32.0731 15.8784C31.2779 15.8648 30.49 16.0307 29.7696 16.3634C29.0492 16.6961 28.4153 17.1868 27.9163 17.7982V17.7876Z"
      fill="black"
    />
    <path d="M12.3436 30.3608V2.23291H17.5475V30.3608H12.3436Z" fill="#DB2357" />
    <path d="M1.24103 29.4712L4.68895 10.186L9.81097 11.0791L6.36661 30.3607L1.24103 29.4712Z" fill="#DB2357" />
  </svg>
)

export default LogoMobile
